@font-face {
  font-family: "Cairo";
  src: url(../../assets/fonts/Cairo/Cairo-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AppleChancery";
  src: url(../../assets/fonts/Apple_Chancery/AppleChancery.ttf)
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url(../../assets/fonts/Roboto/Roboto-Black.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "GrapeNuts";
  src: url(../../assets/fonts/Grape_Nuts/GrapeNuts-Regular.ttf)
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
