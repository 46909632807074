:root {
  --white: #fff;
  --gray-100: #f8f9fa;
  --gray-200: #e9ecef;
  --gray-300: #dee2e6;
  --gray-400: #ced4da;
  --gray-500: #adb5bd;
  --gray-600: #6c757d;
  --gray-700: #495057;
  --gray-800: #343a40;
  --gray-900: #212529;
  --black: #000;

  --yellow: #ffc107;

  --gold: #eeb76b;
  --dark-grey: #212121;
  --gray: #262626;

  --primary: var(--gray);
  --secondary: var(--gold);
  --font-color: var(--gray-100);
}
